.headerContainer {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2;

  .navbar {
    height: 72px;
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .navbar__logo {
      margin: 5px;
      padding: 5px;

      img {
        width: 50px;
        height: 50px;
      }
    }

    .navbar__links {
      display: flex;

      div {
        margin: 10px;
        padding: 10px;
        transition: 0.5s;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 3px;
        transition: 0.5s;
      }

      div:hover {
        color: gray;
      }
      .linkContent {
        gap: 10px;
        font-size: x-large;
        cursor: default;
      }
    }

    .navbar__social {
      margin: 5px;
    }
  }
}
