.social {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30px;

  div {
    transition: 0.5s;
  }
  .x:hover {
    color: #1da1f2;
  }
  .link:hover {
    color: #0072b1;
  }
  .face:hover {
    color: #3b5998;
  }
  .git:hover {
    color: #d16b05;
  }
  .insta:hover {
    color: #feda75;
  }
}
