.box {
  padding: 4rem;
  .boxContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    padding: 1rem;
    .contentDiv {
      display: flex;
      align-items: center;
      gap: 10px;
    }
  }
}
.credit {
  text-align: center;
  gap: 10px;
}
