.headerContainer {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 2;

    .navbar {

        height: 72px;
        color: white;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 0px 30px;
        .navbar__logo {
            margin: 5px;
            padding: 5px;

            img {
                width: 50px;
                height: 50px;
            }
        }
    }
}