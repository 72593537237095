.__about {
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .__title {
    font-size: 40px;
    font-weight: bold;
    margin: 20px;
    color: white;
  }

  .__aboutInfo {
    width: 80%;
    padding: 20px;
    font-size: 2rem;
    text-align: center;

    span {
      font-weight: bold;
    }
  }

  .__aboutSkills {
    display: flex;
    justify-content: center;
    align-items: center;
    .imgIcon {
      text-align: center;
      filter: grayscale(100%);
      height: 10vh;
      transition: 0.2s ease-in-out;
    }
    .imgIcon:hover {
      filter: grayscale(0%);
    }
  }
}

@media screen and (max-width: 800px) {
  .__about {
    .__aboutInfo {
      font-size: 1.2rem;
    }

    .__aboutSkills {
      display: flex;
      flex-direction: column;
      .imgIcon {
        filter: grayscale(0);
      }
    }
  }
}
