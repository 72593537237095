$primary: #11998e;
$secondary: #38ef7d;
$white: #fff;
$gray: #9b9b9b;
.__contact {

    display: flex;
    flex-direction: column;
    align-items: center;
    
    .__title {
        font-size: 30px;
        font-weight: bold;
        margin: 20px;
        color: white;


    }

    .__inputs {

        width: 50%;
   
        gap: 10px;
        
        .__nameInput{
            display: flex;
            input{
                font-family: inherit;
                width: 100%;
                border: 0;
                border-bottom: 2px solid $gray;
                outline: 0;
                color: $white;
                padding: 7px 0;
                background: transparent;
                transition: border-color 0.2s;
                margin: 5px;
                
            }
         
       
     

        }
        .__subjectInput{
            input{
                font-family: inherit;
                width: 98%;
                border: 0;
                outline: 0;
                font-size: 1rem;
                padding: 7px 0;
                background: transparent;
                transition: border-color 0.2s;
                margin: 5px;

                
            }
        
         
       
     

        }
        .__emailInput{
            input{
                font-family: inherit;
                width: 98%;
                border: 0;
                outline: 0;
                font-size: 1rem;
                padding: 7px 0;
                background: transparent;
                transition: border-color 0.2s;
                margin: 5px;

                
            }
        
         
       
     

        }


        .__messgaeInput{
            padding: 20px;
    
        }


    }
    .__submit{
        width: 400px;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        span{
            font-size: 1.5rem;
            transition: 0.5s;
            background-color: white;
            color: black;
            border-radius: 7px;
            width: 60%;
            height: 60%;
            text-align: center;
        }
        span:hover{
            background-color: #00750a;
            color: white;
        }

    }

    .__sent{
        color: white;
    }

}


@media screen and (max-width: 800px) {
    .__inputs{
        width: 100%;
        .__nameInput{
            display: flex;
            input{
                border-bottom: none;
                font-size: 1rem;

                
            }
            
         
       
     

        }
       
    }

}