.__projects {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  gap: 50px;

  .__title {
    font-size: 40px;
    font-weight: bold;
    margin: 20px;
  }

  .__project {
    width: 99%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .__projectInfo {
      width: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      .__projectName {
        margin: 5px;
        padding: 5px;

        img {
          width: 120px;
          height: 50px;
        }
      }
      p {
        padding: 20px;
      }
      .__projectLinks {
        .__git {
          transition: 0.5s;
        }
        .__git:hover {
          color: #d16b05;
        }
        .__goLive {
          width: 200px;
          height: 80px;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: default;
          span {
            display: flex;
            justify-content: center;
            align-items: center;
            transition: 0.5s;
            background-color: white;
            color: black;
            border-radius: 7px;
            width: 60%;
            height: 60%;
            text-align: center;
          }
          span:hover {
            background-color: #00750a;
            color: white;
          }
        }
      }
    }

    .__projectImg {
      width: 50%;
      height: 40%;

      img {
        width: 120%;
        height: 120%;
      }
    }
  }
}

@media screen and (max-width: 800px) {
  .__projects {
    .__project {
      flex-direction: column;

      .__projectInfo {
        width: 100%;
      }

      .__projectImg {
        width: 100%;
        height: 100%;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
