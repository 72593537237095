.home {
    margin-top: 72px;
    // padding-top: 72px;
    height: calc(99.2vh - 72px);
    // background-image: url('../../Utilis/assets/codeimg.jpg');
    // background-repeat: no-repeat;
    // background-size: cover;
    // background-position: center;

    .home_info {
        height: 60%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: white;
    }

    .__title {
        // font-family: 'Lobster', cursive;
        // font-family: 'Permanent Marker', cursive;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: white;

        .__titleData {
            display: flex;
            font-size: 3.5rem;
            // border-bottom: 3px solid red;
            gap: 15px;

        }

    }

    // .scroll {
    //     display: flex;
    //     flex-direction: column;
    //     justify-content: center;
    //     align-items: center;
    //     span{
    //         border: 3px solid rgb(0, 255, 55);
    //         font-size: 1.5rem;
    //         font-family: 'Lobster', cursive ;
    //         font-family: 'Permanent Marker', cursive;
    //         color: white;

    //     }
    //     .arrows {
    //         border: 3px solid red;
    //         width: 60px;
    //         height: 100px;
    //         position: absolute;
    //         left: 50%;
    //         margin-left: -30px;
    //         bottom: 20px;

    //         path {
    //             stroke: #fcfcfc;
    //             fill: transparent;
    //             stroke-width: 4px;
    //             animation: arrow 2s infinite;
    //             -webkit-animation: arrow 2s infinite;
    //         }

    //         @keyframes arrow {
    //             0% {
    //                 opacity: 0
    //             }

    //             40% {
    //                 opacity: 1
    //             }

    //             80% {
    //                 opacity: 0
    //             }

    //             100% {
    //                 opacity: 0
    //             }
    //         }

    //         @-webkit-keyframes arrow {
    //             0% {
    //                 opacity: 0
    //             }

    //             40% {
    //                 opacity: 1
    //             }

    //             80% {
    //                 opacity: 0
    //             }

    //             100% {
    //                 opacity: 0
    //             }
    //         }

    //         path.a1 {
    //             animation-delay: -1s;
    //             -webkit-animation-delay: -1s;
    //             /* Safari 和 Chrome */
    //         }

    //         path.a2 {
    //             animation-delay: -0.5s;
    //             -webkit-animation-delay: -0.5s;
    //             /* Safari 和 Chrome */
    //         }

    //         path.a3 {
    //             animation-delay: 0s;
    //             -webkit-animation-delay: 0s;
    //             /* Safari 和 Chrome */
    //         }
    //     }
    // }

}


@media screen and ( max-width:800px) {
    .home {


        .__title {
    
    
            .__titleData {
                font-size: 7vw;
     
            }
    
        }
    }
    
}